import request from "@/utils/request"

//专题列表的分页查询
export function specialList(data) {
    return request({
        url: '/basicProject/listPage',
        method: 'get',
        params: data
    })
}

//删除专题
export function closeSpecialId(data) {
    return request({
        url: '/basicProject/deleteById',
        method: 'delete',
        params: data
    })
}

//发布专题
export function basicProjectState(data) {
    return request({
        url: '/basicProject/state',
        method: 'post',
        data
    })
}


//新建专题
export function AddSpecial(data) {
    return request({
        url: '/basicProject/save',
        method: 'post',
        data
    })
}


//查询单个专题
export function specialFindById(data) {
    return request({
        url: '/basicProject/findById',
        method: 'get',
        params: data
    })
}


//编辑专题
export function editSpecila(data) {
    return request({
        url: '/basicProject/update',
        method: 'post',
        data
    })
}

//根据基础信息编号查询专题视频
export function relatedVideoJie(data) {
    return request({
        url: '/relatedVideo/listById',
        method: 'get',
        params: data
    })
}

//根据基础信息编号查询专题书籍
export function recommendedBooksJie(data) {
    return request({
        url: '/recommendedBooks/listById',
        method: 'get',
        params: data
    })
}

//视频保存
export function videoUpadta(data) {
    return request({
        url: '/relatedVideo/update',
        method: 'post',
        data
    })
}

//书籍保存
export function booksUpadta(data) {
    return request({
        url: '/recommendedBooks/update',
        method: 'post',
        data
    })
}

//推荐书籍
export function bookRecoom(data) {
    return request({
        url: '/books/listBooks',
        method: 'get',
        params: data
    })
}

//未读留言的数量
export function messageUnread(data) {
    return request({
        url: '/panelDiscussion/count',
        method: 'get',
        params: data
    })
}

//消除未读接口
export function deleteCount(data) {
    return request({
        url: '/panelDiscussion/listCountTrue',
        method: 'get',
        params: data
    })
}

//查询所有数据留言
export function messageListBy(data) {
    return request({
        url: '/panelDiscussion/listPage',
        method: 'get',
        params: data
    })
}