import request from "@/utils/request"

// 图片、文件上传
export function uploadAdmin(data) {
    return request({
        url: "/uploadPublic/upload",
        method: "post",
        data
    })
}

//codeType查询表
export function codeType(data) {
    return request({
        url: '/codeMaster/listAll',
        method: 'get',
        params: data
    })
}

// 查询城市
export function getCity(data) {
    return request({
        url: '/city/listAll',
        method: 'get',
        params: data
    })
}