<template>
  <div class="special">
    <el-card class="special_card">
      <el-form v-model="queryInfo">
        <el-form-item label="标签：">
          <el-radio-group v-model="queryInfo.tag" @change="typeChange">
            <el-radio-button label>不限</el-radio-button>
            <el-radio-button
              v-for="(item, index) in options"
              :key="index"
              :label="item.codeName"
            ></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="请输入专题名称"
            v-model="queryInfo.name"
            class="input-with-select"
          >
            <el-button class="seach" slot="append" @click="search()"
              >搜索</el-button
            >
          </el-input>
          <el-button @click="reset" class="add_special">重置</el-button>
          <el-button type="primary" class="add_special" @click="addClickSpecial"
            >+新建</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="special_position">
        <div class="special_position_top">
          <div v-if="show">
            <div
              class="special_list"
              v-for="(item, index) in specialList"
              :key="index"
            >
              <div class="special_list_flex">
                <div class="special_list_left">
                  <!-- <video :src="item.image" controls></video> -->
                  <img :src="item.picture" />
                  <span v-if="!item.free">免费</span>
                  <span v-else>付费</span>
                </div>
                <div class="special_list_right">
                  <div class="special_list_right_title">{{ item.name }}</div>
                  <div class="special_list_right_tag">
                    <el-tag
                      :key="i"
                      v-for="(tt, i) in item.tag.split(',')"
                      size="small"
                      >{{ tt }}</el-tag
                    >
                  </div>
                  <div class="special_list_right_see">
                    <i class="icon-jr-icon-eye iconfont"></i>
                    <span>{{ item.count }}</span>
                  </div>
                </div>
              </div>
              <div class="special_list_flex_right">
                <el-button
                  plain
                  v-if="item.isRelease"
                  @click="stateClick(item, '取消发布')"
                  class="release width82"
                  >已发布</el-button
                >
                <el-button
                  type="primary"
                  v-else
                  class="width82"
                  @click="stateClick(item, '发布')"
                  >发布专题</el-button
                >
                <el-button type="text" @click="compileClick(item)"
                  >编辑</el-button
                >
                <el-button type="text" @click="closeClick(item)"
                  >删除</el-button
                >
              </div>
              <div class="qrcode">
                <el-popover placement="left-start" width="180" trigger="hover">
                  <div
                    class="pop_image"
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      width: 100%;
                      overflow: hidden;
                    "
                  >
                    <!-- <el-image
                      slot="reference"
                      style="width: 108px; height: 108px; margin: 10px 0"
                      :src="downloadImges"
                    ></el-image>
                    <el-button size="mini" @click="downLoadImg('QRcode')" plain
                      >下载二维码</el-button
                    > -->
                    <p style="margin: 20px 0 10px 0" v-if="item.routeLink">
                      {{ item.routeLink }}
                    </p>
                    <el-button
                      v-if="item.routeLink"
                      size="mini"
                      v-clipboard:copy="item.routeLink"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      plain
                      >复制</el-button
                    >
                  </div>
                  <el-image
                    slot="reference"
                    style="width: 48px; height: 48px"
                    :src="imageUrl"
                  ></el-image>
                </el-popover>
              </div>
            </div>
          </div>
          <div class="no_data" v-else>暂无数据~~</div>
          <div class="special_page">
            <el-pagination
              :current-page="queryInfo.pageNum"
              :page-sizes="[30, 50, 100]"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
const defaultQueryInfo = {
  tag: "", //标签
  pageNumber: 0, // 页码
  pageSize: 10, //每页显示个数
  name: "", //专题名称
};
import { codeType } from "@/api/common.js";
import {
  specialList,
  basicProjectState,
  closeSpecialId,
} from "@/api/special.js";
import axios from "axios";
import { pagination } from "@/mixins/pagination";
export default {
  name: "SpecialList",
  mixins: [pagination],
  created() {
    this.tagList();
    this.search();
  },
  data() {
    return {
      show: false,
      queryInfo: { ...defaultQueryInfo },
      specialList: [],
      options: [],
      itemTagList: [],
      imageUrl:
        "https://elitego-csj-1300257084.cos.ap-shanghai.myqcloud.com/vecto/%E8%89%BE%E5%AA%92.png",
      downloadImges:
        "https://elitego-csj-1300257084.cos.ap-shanghai.myqcloud.com/vecto/QRcode.png",
    };
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  methods: {
    //复制链接
    onCopy(e) {
      // 复制成功
      this.$message({
        message: "复制成功！",
        type: "success",
      });
    },
    onError(e) {
      // 复制失败
      this.$message({
        message: "复制失败！",
        type: "error",
      });
    },
    //重置
    reset() {
      Object.assign(this.queryInfo, defaultQueryInfo);
      this.search();
    },
    //新建路由跳转
    addClickSpecial() {
      this.$router.push({
        path: "/special/addSpecilInfo",
        query: { specialType: 0 },
      });
    },
    //下载图片
    downLoadImg(name) {
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_BASE_API}/enrollment/urlStr`,
        params: { urlStr: `${process.env.VUE_APP_BASE_IMG}` },
        responseType: "blob",
        headers: {
          // 设置请求头
          Authorization: this.Authorization,
        },
      })
        .then((res) => {
          let blob = new Blob([res.data]);
          let href = window.URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
          const a = document.createElement("a"); //创建a标签
          a.style.display = "none";
          a.href = href; // 指定下载'链接
          a.download = name + ".png"; //指定下载文件名
          a.click(); //触发下载
          window.URL.revokeObjectURL(a.href); //释放URL对象
          if (document.body.contains(a)) {
            document.body.removeChild(a); //释放标签
          }
        })
        .catch((err) => {
          this.$message.error("图片下载失败");
        });
    },
    // downLoadImg(url, name) {
    //   let image = new Image();
    //   image.setAttribute("crossOrigin", "anonymous");
    //   image.src = url;
    //   image.onload = () => {
    //     let canvas = document.createElement("canvas");
    //     canvas.width = image.width;
    //     canvas.height = image.height;
    //     let ctx = canvas.getContext("2d");
    //     ctx.drawImage(image, 0, 0, image.width, image.height);
    //     canvas.toBlob((blob) => {
    //       let url = URL.createObjectURL(blob);
    //       this.download(url, name);
    //       // 用完释放URL对象
    //       URL.revokeObjectURL(url);
    //     });
    //   };
    // },
    // download(href, name) {
    //   let eleLink = document.createElement("a");
    //   eleLink.download = name;
    //   eleLink.href = href;
    //   eleLink.click();
    //   eleLink.remove();
    // },
    //获取筛选标签
    async tagList() {
      const { data: res } = await codeType({ codeType: "001" });
      if (res.data) {
        this.options = res.data;
      }
    },
    //选择标签
    typeChange() {
      this.search();
    },
    //默认数据
    async search() {
      const { data: res } = await specialList(this.queryInfo);
      if (res.data) {
        this.specialList = res.data.data;
        this.total = res.data.pageTotal;
        this.show = true;
      } else {
        this.specialList = [];
        this.total = 0;
        this.show = false;
      }
    },
    //发布活动
    stateClick(row, text) {
      this.$confirm(`确认${text}${row.name}吗？`, {
        type: "warning",
      })
        .then(async () => {
          const formData = new FormData();
          formData.append("id", row.id);
          const { data: res } = await basicProjectState(formData);
          if (res.success) {
            this.$messageSuccess(`${text}成功`);
          } else {
            this.$messageError(`${text}失败`);
          }
          this.search();
        })
        .catch(() => {});
    },
    //路由跳转编辑专题
    compileClick(row) {
      this.$router.push({
        path: "/special/editSpecilInfo",
        query: { specialType: 1, id: row.id },
      });
    },
    //删除专题
    closeClick(row) {
      this.$confirm(`确认删除${row.name}吗 ?`, {
        type: "warning",
      })
        .then(async () => {
          const { data: res } = await closeSpecialId({ id: row.id });

          if (res.success) {
            this.$messageSuccess(`${row.name}删除成功`);
            this.search();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.special {
  width: 100%;
  overflow: hidden;

  /deep/ .el-form-item__content {
    display: flex;
  }

  /deep/ .icon-jr-icon-eye {
    color: #4aa9ff;
  }

  .width82 {
    width: 82px !important;
    box-sizing: border-box;
    height: 32px;
  }

  /deep/ .is-active .el-radio-button__inner:hover {
    color: #fff !important;
  }

  .no_data {
    width: 100%;
    overflow: hidden;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 14px;
    color: #c4c4c4;
  }

  .special_list_flex_right {
    .el-button {
      margin-right: 24px;
    }

    /deep/ .el-button--text {
      color: #c4c4c4;
      font-size: 14px;
    }

    /deep/ .release {
      border: 1px solid #8483d0;
      color: #8483d0;
      height: 32px;
      padding: 0 12px;
      font-weight: normal;
    }

    /deep/ .el-button--primary {
      background-color: #8483d0;
      border: 1px solid #8483d0;
      height: 32px;
      padding: 0 12px;
      font-weight: normal;
    }
  }

  .special_card {
    /deep/ .el-radio-button__inner {
      border: none;
      border-radius: 4px !important;
      margin-right: 8px;
    }

    /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background-color: #8483d0 !important;
      box-shadow: -1px 0 0 0 #8483d0;
    }

    /deep/ .el-radio-button__inner:hover {
      color: #8483d0;
    }

    /deep/ .el-input-group {
      width: 415px;
    }

    /deep/ .el-input__inner {
      border: 1px solid #8483d0;
    }

    /deep/ .seach {
      border: 1px solid #8483d0;
      border-radius: 0;
      color: #fff;
      background-color: #8483d0;
    }

    .add_special {
      margin-left: 50px;
    }
  }

  /deep/ .el-card {
    border: none;
  }

  .special_position {
    width: 100%;
    overflow: hidden;

    .special_position_top {
      width: 100%;
      height: 500px;
      overflow: auto;

      .special_list {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        justify-content: space-between;
        padding: 20px 0;
        border-bottom: 1px solid #efefef;

        .qrcode {
          width: 48px;
          height: 48px;
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
        }

        .special_list_flex {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .special_list_left {
          width: 176px;
          margin-right: 12px;
          height: 110px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          position: relative;

          span {
            display: block;
            width: 40px;
            height: 18px;
            left: 10px;
            top: 10px;
            position: absolute;
            border-radius: 4px;
            background-color: #fff;
            line-height: 18px;
            text-align: center;
            color: #151515;
            font-size: 12px;
          }

          video {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 8px;
          }
          img {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 8px;
          }
        }

        .special_list_right {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          overflow: hidden;

          .special_list_right_title {
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #151515;
            font-size: 18px;
            font-weight: bold;
          }

          .special_list_right_tag {
            display: flex;
            flex-direction: row;
            align-items: center;
            overflow: hidden;
            margin: 12px 0;
            white-space: nowrap;

            /deep/ .el-tag {
              background-color: #e8e9fa;
              color: #8483d0;
              border-color: #e8e9fa;
              margin-right: 12px;
            }
          }

          .special_list_right_see {
            display: flex;
            flex-direction: row;
            align-items: center;

            i {
              margin-right: 8px;
            }

            span {
              color: #3b414f;
              font-size: 14px;
            }
          }
        }
      }
    }

    .special_page {
      text-align: center;
      width: 100%;
      margin-top: 20px;
    }
  }
}
</style>